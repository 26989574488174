import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Rastreamento from './pages/rastreamento';

function Rotas() {

    return (
        <Router>
                <Routes>
                    <Route exact path='/' element={<Rastreamento />} />
                </Routes>
        </Router>
    );
}

export default Rotas;