import axios from "axios";

export const api = axios.create({
    //baseURL : 'http://localhost:9005',
    //baseURL : 'http://192.168.3.124:9005',
    baseURL : 'https://dashboardapi.easysoftsistemas.com.br',
});

export const rastrear = async (cnpj_local, cod_rastreamento) => {
    return api.get('/rastreamento', { params : { cnpj_local, cod_rastreamento } });
}

