import { useEffect, useState } from "react";
import { api, rastrear } from "../../services/api";

const Rastreamento = () => {

    const [res, setRes] = useState({
        id: '',
        cod_rastreamento: '',
        n_codigoproduto: '',
        str_descricao: '',
        str_variedade: '',
        str_unidade: '',
        n_peso: '',
        d_embalagem: '',
        logo_loja: '',
        str_razao_social: '',
        str_fantasia: '',
        str_cnpj: '',
        str_inscestadual: '',
        str_estado: '',
        str_cep: '',
        str_endereco: '',
        str_fone1: '',
        str_complemento: '',
        str_bairro: '',
        str_cidade: '',
        str_email: '',
        str_latitude: '0,0',
        str_longitude: '0,0'
    });
    const [cnpjLocal, setCnpjLocal] = useState('');
    const [codRastreamento, setCodRastreamento] = useState('');

    useEffect(() => {
        const queryString = window.location.search;
        // Criando um objeto URLSearchParams para manipular os parâmetros
        const urlParams = new URLSearchParams(queryString);

        // Obtendo os valores dos parâmetros
        const cnpjLocal = urlParams.get('cnpj_local');
        const codRastreamento = urlParams.get('cod_rastreamento');

        setCnpjLocal(cnpjLocal);
        setCodRastreamento(codRastreamento);

        if (cnpjLocal && codRastreamento) {
            rastrearF(cnpjLocal, codRastreamento);
        }
    }, []);

    const handlePesquisar = () => {
        if (cnpjLocal && codRastreamento) {
            rastrearF(cnpjLocal, codRastreamento);
        }
        else {
            alert('Por favor preencha corretamente o cnpj e o código para rastreamento')
        }
    }

    const rastrearF = async (cnpj, rastreio) => {
        try {
            var response = await rastrear(cnpj, rastreio);
            if (JSON.stringify(response.data) != '{}') {
                setRes(response.data);
            }
            else {
                setRes({
                    id: '',
                    cod_rastreamento: '',
                    n_codigoproduto: '',
                    str_descricao: '',
                    str_variedade: '',
                    str_unidade: '',
                    n_peso: '',
                    d_embalagem: '',
                    logo_loja: '',
                    str_razao_social: '',
                    str_fantasia: '',
                    str_cnpj: '',
                    str_inscestadual: '',
                    str_estado: '',
                    str_cep: '',
                    str_endereco: '',
                    str_fone1: '',
                    str_complemento: '',
                    str_bairro: '',
                    str_cidade: '',
                    str_email: '',
                    str_latitude: '0,0',
                    str_longitude: '0,0'
                });
                alert('Nenhum item encontrado')
            }
        }
        catch (err) {
            alert(err.data)
        }
    }

    function formatDate(dateString) {
            const [year, month, day] = dateString.split('-');

            if (`${day}/${month}/${year}` == 'undefined/undefined/') {
                return ''
            }
            else {
                return `${day}/${month}/${year}`;
            }
    }

    return (
        <div className="container mt-5">
            <div className="card mb-2">
                <div className="card-body">
                    <div className="row g-3 p-3">
                        <div className="col-md-2">
                            <img src={`data:image/jpeg;base64,${res.logo_loja}`} className="img-thumbnail img-fluid" alt="Logo loja" />
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="cnpj" className="form-label">CNPJ</label>
                            <input type="text" maxLength={14} className="form-control" id="cnpj" value={cnpjLocal} onChange={(e) => setCnpjLocal(e.target.value.replace(/[^0-9]/g, ''))} />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="rastreio" className="form-label">CÓDIGO DE RASTREIO</label>
                            <input type="text" className="form-control" id="rastreio" value={codRastreamento} onChange={(e) => setCodRastreamento(e.target.value)} />
                        </div>
                        <div className="col-md-1">
                            <label className="form-label">&nbsp;</label>
                            <button className="btn btn-primary" onClick={handlePesquisar}>Pesquisar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <div className="row g-3 p-3">
                        <div className="col-md-3">
                            <label htmlFor="n_codigoproduto" className="form-label">Código do produto</label>
                            <input disabled type="text" className="form-control" id="n_codigoproduto" value={res.n_codigoproduto} />
                        </div>
                        <div className="col-md-9">
                            <label htmlFor="str_descricao" className="form-label">Descrição</label>
                            <input disabled type="text" className="form-control" id="str_descricao" value={res.str_descricao} />
                        </div>
                    </div>
                    <div className="row g-3 p-3">
                        <div className="col-md-3">
                            <label htmlFor="d_embalagem" className="form-label">Data embalagem</label>
                            <input disabled type="text" className="form-control" id="d_embalagem" value={formatDate(res.d_embalagem)} />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="str_variedade" className="form-label">Variedade</label>
                            <input disabled type="text" className="form-control" id="str_variedade" value={res.str_variedade} />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="str_unidade" className="form-label">Unidade</label>
                            <input disabled type="text" className="form-control" id="str_unidade" value={res.str_unidade} />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="n_peso" className="form-label">Peso Kg</label>
                            <input disabled type="text" className="form-control" id="n_peso" value={res.n_peso} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <div className="row g-3 p-3">
                        <div className="col-md-6">
                            <label htmlFor="str_razao_social" className="form-label">Distribuido por</label>
                            <input disabled type="text" className="form-control" id="str_razao_social" value={res.str_razao_social} />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="str_fantasia" className="form-label">Distribuido por</label>
                            <input disabled type="text" className="form-control" id="str_fantasia" value={res.str_fantasia} />
                        </div>
                    </div>
                    <div className="row g-3 p-3">
                        <div className="col-md-3">
                            <label htmlFor="str_cnpj" className="form-label">CNPJ Produtor</label>
                            <input disabled type="text" className="form-control" id="str_cnpj" value={res.str_cnpj} />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="str_inscestadual" className="form-label">Inscrição Estadual</label>
                            <input disabled type="text" className="form-control" id="str_inscestadual" value={res.str_inscestadual} />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="str_estado" className="form-label">UF</label>
                            <input disabled type="text" className="form-control" id="str_estado" value={res.str_estado} />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="str_cep" className="form-label">CEP</label>
                            <input disabled type="text" className="form-control" id="str_cep" value={res.str_cep} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <div className="row g-3 p-3">
                        <div className="col-md-10">
                            <label htmlFor="str_endereco" className="form-label">Endereço</label>
                            <input disabled type="text" className="form-control" id="str_endereco" value={res.str_endereco} />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="cnpj" className="form-label">Número</label>
                            <input disabled type="text" className="form-control" id="cnpj" />
                        </div>
                    </div>
                    <div className="row g-3 p-3">
                        <div className="col-md-3">
                            <label htmlFor="str_fone1" className="form-label">Telefone:</label>
                            <input disabled type="text" className="form-control" id="str_fone1" value={res.str_fone1} />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="str_complemento" className="form-label">Complemento</label>
                            <input disabled type="text" className="form-control" id="str_complemento" value={res.str_complemento} />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="str_bairro" className="form-label">Bairro</label>
                            <input disabled type="text" className="form-control" id="str_bairro" value={res.str_bairro} />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="str_cidade" className="form-label">Município</label>
                            <input disabled type="text" className="form-control" id="str_cidade" value={res.str_cidade} />
                        </div>
                    </div>
                    <div className="row g-3 p-3">
                        <div className="col-md-12">
                            <label htmlFor="str_email" className="form-label">E-mail:</label>
                            <input disabled type="text" className="form-control" id="str_email" value={res.str_email} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <div className="row mb-2">
                        <div className="col-md-3">
                            <label htmlFor="str_latitude" className="form-label">Latitude</label>
                            <input disabled type="text" className="form-control" id="str_latitude" value={res.str_latitude} />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="str_longitude" className="form-label">Longitude</label>
                            <input disabled type="text" className="form-control" id="str_longitude" value={res.str_longitude} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <iframe
                                width="100%"
                                height="600"
                                frameBorder="0"
                                src={"https://maps.google.com.br/maps?q=" + res.str_latitude.replace(",", ".") + "," + res.str_longitude.replace(",", ".") + "&output=embed&dg=oo"}
                            >
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Rastreamento;